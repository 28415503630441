var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-table',{ref:"tableBox",attrs:{"columns":_vm.columns,"toolActions":_vm.toolActions,"show-search":true,"searchForm":_vm.searchForm,"showToolBar":true,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"dataSourceApi":_vm.dataSourceApis,"dataSourceParams":_vm.dataSourceParams,"scroll":{ y: '55vh', x: '80vw' }},on:{"delect":_vm.del,"export":_vm.expor,"exportAll":_vm.exportAll,"add":_vm.add,"action-column-click":_vm.actionColumnClick,"pop-confirm":_vm.popConfirm},scopedSlots:_vm._u([{key:"age",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(new Date().getFullYear() - parseInt(record.identityCard.substr(6, 4)))+" ")])}},{key:"cellName",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.cellName ? record.cellName : "--")+" ")])}},{key:"petitionMatters_dictText",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.petitionMatters_dictText ? record.petitionMatters_dictText : "--")+" ")])}},{key:"petitionNum",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.petitionNum ? record.petitionNum : "--")+" ")])}},{key:"communicationMode_dictText",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.communicationMode_dictText ? record.communicationMode_dictText : "--")+" ")])}},{key:"measure_dictText",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.measure_dictText ? record.measure_dictText : "--")+" ")])}},{key:"riskLevel_dictText",fn:function({ record }){return _c('div',{staticClass:"operName"},[(record.riskLevel)?_c('a-button',{class:{
          grey: record.riskLevel === '3',
          red: record.riskLevel === '1',
          yellow: record.riskLevel === '2',
        }},[_vm._v(_vm._s(record.riskLevel_dictText))]):_c('a-input',{staticStyle:{"border":"none","text-align":"center","background-color":"transparent"},attrs:{"disabled":"","value":"--"}})],1)}}])},[_c('div',{attrs:{"slot":"currentAreaCode"},slot:"currentAreaCode"},[_c('a-cascader',{ref:"cascader",attrs:{"getPopupContainer":(triggerNode) => triggerNode.parentNode,"options":_vm.options,"load-data":_vm.loadData,"placeholder":"请选择","model":_vm.currentAreaCode},on:{"change":_vm.onChange},model:{value:(_vm.currentAreaCode1),callback:function ($$v) {_vm.currentAreaCode1=$$v},expression:"currentAreaCode1"}})],1)]),_c('add-people',{ref:"specialWomenInfo",on:{"refsh":_vm.refsh}}),_c('watch-People',{ref:"specialWomenInfoDetail"}),_c('visit-records',{ref:"visitRecords"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }